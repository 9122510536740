<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'
import userApi from '../../api/user'

export default {
    name: 'login',
    data(){
        return {
            loginScanTimer: '',
            loginScanCode: '',
            loginForm:{
                email: '',
                password: ''
            }
        }
    },
    computed: {
        loginScanCodeText() {
            return "type=login&id=" + this.loginScanCode
        }
    },
    created(){
        this.getLoginScanCode()
    },
    methods:{
        ...mapActions('client', ['login', 'appLogin']),
        getLoginScanCode(){
            userApi.getLoginScanCode().then(resp=>{
                this.loginScanCode = resp
            })
            this.loginScanTimer = setTimeout(this.queryLoginScanCode, 2000)
        },
        queryLoginScanCode() {
            userApi.queryLoginScanCode(this.loginScanCode).then(resp=>{
                console.log(resp)
                clearTimeout(this.loginScanTimer)
                this.appLogin(resp)
                this.$message({
                    message: 'login successfully',
                    type: 'success'
                })
                window.location.href = '/'
            }).catch(()=>{
                clearTimeout(this.loginScanTimer)
                this.loginScanTimer = setTimeout(this.queryLoginScanCode, 2000)
                console.log('retry check login scan code', this.loginScanCode)
            })
        },
        isEmail(str) {
            var reg = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
            return  reg.test(str);
        },
        submitLogin(){
            if(this.loginForm.email.length === 0 || this.loginForm.password.length === 0) return
            if(!this.isEmail(this.loginForm.email)){
                this.$message({
                    message: 'email format error',
                    type: 'error'
                })
                return
            }
            if(this.loginForm.password.length < 6){
                this.$message({
                    message: 'password must be more than 6 characters',
                    type: 'error'
                })
                return
            }
            let sha256 = require('js-sha256')
            this.loginForm.password = sha256(this.loginForm.password)
            this.login(this.loginForm).then(()=>{
                this.$message({
                    message: 'login successfully',
                    type: 'success'
                })
                if(this.$route.query.callbackUrl && this.$route.query.callbackUrl.length > 0){
                    window.location.href = decodeURI(this.$route.query.callbackUrl)
                } else {
                    window.location.href = '/'
                }
            }).catch(()=>{
                this.$message.error('email or password not match, please try again')
                this.loginForm.password = ''
            })
        }
    }
}
</script>

<style scoped>
.el-card{
    border: 1px solid #fff !important;
}
</style>