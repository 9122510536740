<template>
  <base-web-page>
    <template v-slot:main>
        <el-main style="margin:0 auto;">
          <el-card shadow="never" style="width:600px;">
              <div slot="header">
                  <h1>Login</h1>
              </div>
              <div style="display:-webkit-box;">
                  <div style="text-align:left;width:300px;">
                      <div style="font-size:14px;font-weight:bold;">Email</div>
                      <div style="margin-top:20px;"><el-input v-model="loginForm.email" placeholder="" type="email"></el-input></div>
                      <div style="margin-top:20px;;font-size:14px;font-weight:bold;">Password</div>
                      <div style="margin-top:20px;"><el-input placeholder="" v-model="loginForm.password" show-password></el-input></div>
                      <div style="margin-top:20px;"><el-button type="danger" style="width:300px;" @click="submitLogin">Sign In</el-button></div>
                      <div style="margin-top:20px;"><el-link href="/register">Create account</el-link></div>
                  </div>
                  <div style="margin-left:20px;border: 1px solid #EBEEF5; width:210px;-webkit-box-flex:1;">
                      <vue-qr :text="loginScanCodeText" :size="200"></vue-qr>
                      <div style="font-size:12px;font-weight:bold;">Open app scan to login</div>
                      <div style="font-size:12px;margin-top:10px; color:#409EFF;">
                          <el-tooltip class="item" effect="dark" content="App is on the way ! Please wait for announcement !" placement="top">
                          <el-link href="#" type="primary" style="font-size:12px;" icon="el-icon-download">Download APP</el-link>
                          </el-tooltip>
                      </div>
                  </div>
              </div>
          </el-card>
      </el-main>
    </template>
  </base-web-page>
</template>

<script>
import BaseWebPage from '../../components/base/BaseWebPage.vue'
import vueQr from 'vue-qr'
import ModelLogin from '../viewmodel/ModelLogin.vue'


export default {
    extends: ModelLogin,
    name: 'login',
    components: {
        BaseWebPage,
        vueQr
    }
}
</script>

<style scoped>
.el-card{
    border: 1px solid #fff !important;
}
</style>